<template>
  <div class="mt-3">
    <FloatLabelDropdownPanel
      ref="commentInputRef"
      id="comment"
      :selectedItemName="commentComputed"
      :label="t('purchase.comment.label')"
      :selectLabel="t('placeholder.type', { property: t('purchase.comment.label').toLowerCase() })"
      :placeholder="t('placeholder.type', { property: t('purchase.comment.label').toLowerCase() })"
      maxlength="15000"
      dataTestId="purchase-comment"
      :disabled="false"
      class="c-comment"
      @toggleDropdownPanel="showCommentPanel"
    />
    <CommentPanel ref="commentPanelRef" v-model:comment="commentComputed" @tabKeyPressed="tabKeyPressed" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import CommentPanel from "./CommentPanel.vue";

const { t } = useI18n();

const props = defineProps<{
  comment: string;
}>();
const emit = defineEmits<{
  (e: "update:comment", value: string): void;
}>();

const commentComputed = computed<string>({
  get: () => {
    return props.comment.trim() ?? "";
  },
  set: (value) => {
    emit("update:comment", value);
  },
});

const commentPanelRef = ref();
const showCommentPanel = (event: Event) => {
  const key = (event as KeyboardEvent).key;
  if (key === "Shift" || key === "Escape" || key === "Tab") {
    return;
  }

  commentPanelRef.value.toggle(event);
};
const commentInputRef = ref();
const tabKeyPressed = () => commentInputRef.value.$el?.querySelector("button")?.focus();
</script>
