<template>
  <DataTable
    :value="goodsReceptionHistory"
    class="c-compact-datatable"
    striped-rows
    data-testid="goods-reception-history-table"
  >
    <Column
      data-testid="receivedDate"
      field="receivedDate"
      :header="t('goods-reception-history.received-date')"
      class="c-margin-auto text-center"
      :pt="{
        bodyCell: {
          'data-testid': 'received-date',
        },
      }"
    >
      <template #body="slotProps">
        <span>{{ d(slotProps.data.receivedDate, "long") }}</span>
      </template>
    </Column>
    <Column
      field="initials"
      :header="t('goods-reception-history.initials')"
      class="c-margin-auto text-center"
      :pt="{
        bodyCell: {
          'data-testid': 'initials',
        },
      }"
    />
    <Column
      data-testid="receivedQuantity"
      field="receivedQuantity"
      :header="t('goods-reception-history.quantity-received')"
      class="c-margin-auto text-center"
      :pt="{
        bodyCell: {
          'data-testid': 'received-quantity',
        },
      }"
    />
    <Column
      data-testid="batchNumber"
      field="batchNumber"
      :header="t('goods-reception-history.batch-number')"
      class="c-margin-auto text-center"
      :pt="{
        bodyCell: {
          'data-testid': 'batch-number',
        },
      }"
    />
    <Column
      data-testid="reference"
      field="reference"
      :header="t('goods-reception-history.reference')"
      class="c-margin-auto text-center"
      :pt="{
        bodyCell: {
          'data-testid': 'reference',
        },
      }"
    />
    <Column
      field="receivedCost"
      :header="t('goods-reception-history.received-cost')"
      class="c-margin-auto text-center"
      :pt="{
        bodyCell: {
          'data-testid': 'received-cost',
        },
      }"
    >
      <template #body="{ data }">
        <span>{{ n(data.receivedCost, "currency", data.currencyIso) }}</span>
      </template>
    </Column>
    <Column
      data-testid="goodsReceptionNumber"
      field="goodsReceptionNumber"
      :header="t('goods-reception-history.goods-reception-number')"
      class="c-margin-auto text-center"
      :pt="{
        bodyCell: {
          'data-testid': 'goods-reception-number',
        },
      }"
    />
  </DataTable>
</template>
<script setup lang="ts">
import { GoodsReception } from "@/repository/goods-reception/model/GoodsReception";
import { GoodsReceptionHistory } from "@/repository/goods-reception/model/GoodsReceptionHistory";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t, d, n } = useI18n();

const props = defineProps<{
  goodsReceptions: GoodsReception[];
  purchaseOrderLineId: string;
}>();

const goodsReceptionHistory = computed(() => {
  const goodsReceptionHistory: GoodsReceptionHistory[] = [];
  props.goodsReceptions.forEach((goodsReception) => {
    goodsReception.goodsReceptionLines
      .filter((goodsReceptionLine) => goodsReceptionLine.purchaseOrderLineId === props.purchaseOrderLineId)
      .forEach((goodsReceptionLine) => {
        const history = new GoodsReceptionHistory();
        history.batchNumber = goodsReceptionLine.batchNumber;
        history.goodsReceptionNumber = goodsReception.goodsReceptionNumber;
        history.initials = goodsReception.employeeInitials;
        history.purchasePrice = goodsReceptionLine.purchasePrice;
        history.reference = goodsReceptionLine.reference;
        history.receivedDate = goodsReception.receivedDate;
        history.receivedQuantity = goodsReceptionLine.receivedQuantity;
        history.receivedCost = goodsReceptionLine.receivedCost;
        history.currencyIso = goodsReception.currencyIso;
        goodsReceptionHistory.push(history);
      });
  });
  return goodsReceptionHistory;
});
</script>

<style lang="scss" scoped>
.c-compact-datatable :deep(.p-datatable-thead > tr > th.c-margin-auto > div > span) {
  margin: auto;
}
</style>
